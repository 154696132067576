<template>
  <div class="default-filter" v-click-outside="hideModal">
    <div @click="isOpen = !isOpen" class="default-filter__content">
      <i class="icon-filter"></i>
      <span>{{ filterValue ? filterValue.name : items[0].name }}</span>
    </div>
    <div :class="['default-filter__modal', isOpen ? 'default-filter__modal_opened' : '']">
      <div class="default-filter__modal__content">
        <span
          :class="[
            'default-filter__modal__content__item',
            filterValue && filterValue.name === item.name
              ? 'default-filter__modal__content__item_active'
              : ''
          ]"
          v-for="item in items"
          :key="item.name"
          @click="setFilter(item)"
          >{{ item.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "DefaultFilter",
  mixins: [],
  props: ["filterValue", "items"],
  components: {},
  data() {
    return {
      isOpen: false
    };
  },
  mounted() {},
  methods: {
    hideModal() {
      this.isOpen = false;
    },
    setFilter(item) {
      this.$root.$emit("changeFilter", item);
    }
  },
  computed: {},
  watch: {},
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.default-filter {
  display: flex;
  justify-content: flex-end;
  user-select: none;
  position: relative;
  width: 150px;
  &__content {
    display: flex;
    align-items: center;

    color: $color-interactive;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;

    cursor: pointer;

    > span {
      transition: all 0.2s ease;
    }

    .icon-filter {
      margin-right: 10px;
      font-size: 20px;
    }

    @media (hover: hover) {
      &:hover {
        color: $color-button-hover;
      }
    }

    &:active {
      color: $color-button-click;
    }
  }

  &__modal {
    top: calc(100% + 5px);
    position: absolute;
    padding: 6px 10px;
    width: 135px;
    z-index: 10;
    border: 1px solid $color-text-secondary;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    background-color: $color-white;
    transition: all 0.2s ease;
    transform: scale(0);
    opacity: 0;

    &_opened {
      transform: scale(1);
      opacity: 1;
    }

    &__content {
      display: flex;
      flex-direction: column;

      &__item {
        &:not(:last-child) {
          margin-bottom: 13px;
        }

        font-size: 13px;
        line-height: 13px;
        font-weight: 400;
        color: $color-other-2;

        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          color: $color-text-primary-2;
        }

        &_active {
          color: $color-text-primary-2;
        }
      }
    }
  }
}
</style>
